<template>
  <div class="container">
    <el-card class="box-card">
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="直播间" prop="roomNo"></el-table-column>
        <el-table-column align="center" label="打赏人" prop="nickName"></el-table-column>
        <el-table-column align="center" label="礼物名称">
          <template slot-scope="scope">
            <div class="gift-box">
              <div>
                <el-image class="gift-img" :previewSrcList="[scope.row.giftImg]" :src="scope.row.giftImg"
                          fit="fill"></el-image>
              </div>
              <span>{{ scope.row.giftName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="金豆数量(个)" prop="giftAmount"></el-table-column>
        <el-table-column align="center" label="礼物价值(元)">
          <template slot-scope="scope">
            <span>￥{{ scope.row.giftTotalCost }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="打赏时间" prop="createTime"></el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageNum"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {liveRewardList} from '@/apis/live'

export default {
  name: "rewardList",
  data() {
    return {
      tableData: [],
      query: {
        pageNum: 1,
        pageSize: 10
      },
      total: null,
      loading: false
    }
  },
  created() {
    this.rewardList()
  },
  methods: {
    async rewardList() {
      this.loading = true
      const resp = await liveRewardList(this.query)
      const {list, total, pageNum, pageSize} = resp.data
      this.tableData = list
      this.total = total
      this.query.pageNum = pageNum
      this.query.pageSize = pageSize
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    handleSizeChange(pageSize) {
      this.query.pageSize = pageSize
      this.rewardList()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.query.pageNum = pageNum
      this.rewardList()
    }
  }
}
</script>

<style lang="less" scoped>
.gift-box {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .gift-img {
    width: 30px;
    height: 30px;
  }

}
</style>